import { ReactElement } from 'react';
import styles from './BottomBar.module.scss';

export default function BottomBar(): ReactElement {
    function scrollToAbout(): void {
        window.scrollTo({
            top: 950,
            behavior: 'smooth',
        });
    }
    function scrollToWork(): void {
        window.scrollTo({
            top: 2050,
            behavior: 'smooth',
        });
    }
    function scrollToContact(): void {
        window.scrollTo({
            top: 100000,
            behavior: 'smooth',
        });
    }
    return (
        <div className={styles['content-container']}>
            <div className={styles['button-container']}>
                <button
                    onClick={scrollToAbout}
                    className={styles['nav-options-button']}
                >
                    About
                </button>
                <button
                    onClick={scrollToWork}
                    className={styles['nav-options-button']}
                >
                    Work
                </button>
                <button
                    onClick={scrollToContact}
                    className={styles['nav-options-button']}
                >
                    Contact
                </button>
            </div>
            <div className={styles['text-container']}>
                <h1 className={styles['sub-title']}>©Diane Vallesteros</h1>
                <h1 className={styles['sub-title']}>Built by Matthew Sauder</h1>
                <h1 className={styles['sub-title']}>
                    Designed by Celina Gallardo
                </h1>
            </div>
        </div>
    );
}
