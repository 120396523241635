import { ReactElement } from 'react';
import { AboutMeModel } from '../../models/aboutmemodel.model';
import styles from './AboutMe.module.scss';

interface AboutProps {
    content: AboutMeModel;
}

export default function AboutMe(props: AboutProps): ReactElement {
    function scrollToContact(): void {
        window.scrollTo({
            top: 100000,
            behavior: 'smooth',
        });
    }

    return (
        <div>
            <img
                className={styles['image']}
                src={props.content.backgroundImage.url}
            ></img>
            {/* <div className={styles['top-angle']}></div> */}
            <div className={styles['content-container']}>
                <div className={styles['text-container']}>
                    <h2 className={styles['header']}>{props.content.header}</h2>
                    <h1
                        className={styles['description']}
                        dangerouslySetInnerHTML={{
                            __html: props.content.description.html,
                        }}
                    ></h1>
                    <div className={styles['button-section']}>
                        <div
                            className={styles['button-container']}
                            onClick={scrollToContact}
                        >
                            <a className={styles['button-text']}>
                                Let&apos;s Collaborate
                            </a>
                        </div>
                    </div>
                </div>
                <div id="about" className={styles['image-container']}>
                    <img
                        className={styles['picture-vertical']}
                        src={props.content.picture1.url}
                        alt="Profile Picture of Diane"
                    ></img>
                    <img
                        className={styles['picture-vertical']}
                        src={props.content.picture2.url}
                        alt="Profile Picture of Diane"
                    ></img>
                    <img
                        className={styles['picture-vertical']}
                        src={props.content.picture3.url}
                        alt="Profile Picture of Diane"
                    ></img>
                </div>
            </div>
        </div>
    );
}
