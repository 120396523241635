import React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import AboutMe from '../../components/AboutMe/AboutMe';
import BottomBar from '../../components/BottomBar/BottomBar';
import Contact from '../../components/Contact/Contact';
import EmailButton from '../../components/EmailButton/EmailButton';

import SelectedWorks from '../../components/SelectedWorks/SelectedWorks';
import { AboutMeModel } from '../../models/aboutmemodel.model';
import { ContactModel } from '../../models/contact.model';
import { AboutMeService } from '../../services/aboutme-service';
import { ContactService } from '../../services/contact-service';
import styles from './Home.module.scss';

export default function Home(): ReactElement {
    const [aboutContent, setAbout] = useState<AboutMeModel[]>([]);
    const [aboutContact, setContact] = useState<ContactModel[]>([]);

    const aboutSection = React.useRef<HTMLDivElement>(null);
    const workSection = React.useRef<HTMLDivElement>(null);
    const contactSection = React.useRef<HTMLDivElement>(null);

    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    function scrollToAbout(): void {
        window.scrollTo({
            top: 650,
            behavior: 'smooth',
        });
    }

    function scrollToWork(): void {
        window.scrollTo({
            top: workSection.current?.offsetTop,
            behavior: 'smooth',
        });
    }

    function scrollToContact(): void {
        window.scrollTo({
            top: contactSection.current?.offsetTop,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        AboutMeService.GetAboutMeText().then((abouts: AboutMeModel[]) => {
            setAbout(abouts);
        });
    }, []);

    useEffect(() => {
        ContactService.GetContactText().then((contacts: ContactModel[]) => {
            setContact(contacts);
        });
    }, []);

    function getAboutMe(): ReactElement | ReactElement[] {
        return aboutContent.map((about: AboutMeModel) => {
            return (
                <div key={about.description.html}>
                    <AboutMe content={about} />
                </div>
            );
        });
    }

    function getContact(): ReactElement | ReactElement[] {
        return aboutContact.map((contact: ContactModel) => {
            return (
                <div key={contact.description.html}>
                    <Contact content={contact} />
                </div>
            );
        });
    }

    function getEmail(): ReactElement | ReactElement[] {
        return aboutContact.map((contact: ContactModel) => {
            return (
                <div key={contact.description.html}>
                    <div>
                        <EmailButton content={contact} />
                    </div>
                </div>
            );
        });
    }

    function getTopBar(): ReactElement {
        return (
            <div>
                <div className={styles['content-container']}>
                    <div className={styles['text-container']}>
                        <div className={styles['spaced-name']}>
                            <h1 className={styles['title']}>D</h1>
                            <h1 className={styles['title']}>I</h1>
                            <h1 className={styles['title']}>A</h1>
                            <h1 className={styles['title']}>N</h1>
                            <h1 className={styles['title']}>E</h1>
                            <h1 className={styles['title']}>‎</h1>
                            <h1 className={styles['title']}>V</h1>
                            <h1 className={styles['title']}>A</h1>
                            <h1 className={styles['title']}>L</h1>
                            <h1 className={styles['title']}>L</h1>
                            <h1 className={styles['title']}>E</h1>
                            <h1 className={styles['title']}>S</h1>
                            <h1 className={styles['title']}>T</h1>
                            <h1 className={styles['title']}>E</h1>
                            <h1 className={styles['title']}>R</h1>
                            <h1 className={styles['title']}>O</h1>
                            <h1 className={styles['title']}>S</h1>
                        </div>

                        <h1 className={styles['sub-title']}>
                            Composer & Pianist
                        </h1>
                    </div>

                    <div className={styles['button-container']}>
                        <button
                            onClick={scrollToAbout}
                            className={styles['nav-options-button']}
                        >
                            About
                        </button>
                        <button
                            onClick={scrollToWork}
                            className={styles['nav-options-button']}
                        >
                            Work
                        </button>
                        <button
                            onClick={scrollToContact}
                            className={styles['nav-options-button']}
                        >
                            Contact
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles['home']}>
            {getTopBar()}
            {getEmail()}
            <div className={styles['about']} ref={aboutSection}></div>
            {getAboutMe()}
            <div ref={workSection}></div>
            <SelectedWorks />
            <div ref={contactSection}></div>
            {getContact()}
            <BottomBar />
        </div>
    );
}
