import { ReactElement, useEffect, useState } from 'react';
import { WorkModel } from '../../models/work.model';
import { WorkService } from '../../services/work-service';
import WorkSection from '../WorkSection/WorkSection';
import styles from './SelectedWorks.module.scss';

export default function SelectedWorks(): ReactElement {
    const [contentList, setContentList] = useState<WorkModel[]>([]);

    useEffect(() => {
        WorkService.GetWorkList().then((works: WorkModel[]) => {
            setContentList(works);
        });
    }, []);

    function generateWorks(): ReactElement | ReactElement[] {
        return contentList.map((content: WorkModel) => {
            return (
                <div key={content.sectionTitle}>
                    <WorkSection content={content} />
                </div>
            );
        });
    }

    return (
        <div>
            <div className={styles['content-container']}>{generateWorks()}</div>
        </div>
    );
}
