import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import styles from './App.module.scss';
import ScrollButton from '../../components/ScrollButton/ScrollButton';

function App(): ReactElement {
    return (
        <div className={styles['app-container']}>
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                </Switch>
            </div>
            <div>
                <ScrollButton />
            </div>
        </div>
    );
}

export default App;
