import { request, gql } from 'graphql-request';
import { GRAPH_URL } from '../constants/graphql-constants';
import { VideoModel } from '../models/video.model';

export namespace VideoService {
    export function GetVideoList(): Promise<VideoModel[]> {
        const query: string = gql`
            {
                videos {
                    category
                    videoUrl
                    title
                    subTitle
                    role
                }
            }
        `;

        return request<VideoModel[]>(GRAPH_URL, query).then((result: any) => {
            return result.videos;
        });
    }
}
