import { request, gql } from 'graphql-request';
import { GRAPH_URL } from '../constants/graphql-constants';
import { WorkModel } from '../models/work.model';

export namespace WorkService {
    export function GetWorkList(): Promise<WorkModel[]> {
        const query: string = gql`
            {
                works {
                    sectionTitle
                    subTitleOne
                    subTitleTwo
                    reelCrafterUrl
                    reelCrafterHeight
                    reelCrafterImage {
                        url
                    }
                }
            }
        `;

        return request<WorkModel[]>(GRAPH_URL, query).then((result: any) => {
            return result.works;
        });
    }
}
