import { ReactElement, useEffect, useState } from 'react';
import { VideoModel } from '../../models/video.model';
import { WorkModel } from '../../models/work.model';
import { VideoService } from '../../services/video-service';
import styles from './WorkSection.module.scss';

interface WorkProps {
    content: WorkModel;
}

export default function WorkSection(props: WorkProps): ReactElement {
    const [contentList, setContentList] = useState<VideoModel[]>([]);

    useEffect(() => {
        VideoService.GetVideoList().then((videos: VideoModel[]) => {
            setContentList(videos);
        });
    }, []);

    function hasReelCrafterUrl(): boolean {
        return !!props.content.reelCrafterUrl;
    }

    function hasReelCrafterImage(): boolean {
        return !!props.content.reelCrafterImage;
    }

    function generateVideos(): ReactElement | ReactElement[] {
        return contentList.map((video: VideoModel) => {
            //matches projects category
            if (video.category === props.content.sectionTitle) {
                return (
                    <div key={video.title} className={styles['video-content']}>
                        <div className={styles['video-box']}>
                            <iframe
                                allowFullScreen
                                width="100%"
                                height="315"
                                frameBorder="0"
                                src={video.videoUrl}
                            ></iframe>
                        </div>
                        <h1 className={styles['video-title']}>{video.title}</h1>
                        <h1 className={styles['video-sub-title']}>
                            {video.subTitle}
                        </h1>
                        <h1 className={styles['video-sub-title']}>
                            {video.role}
                        </h1>
                    </div>
                );
            } else {
                return <div></div>;
            }
        });
    }

    return (
        <div className={styles['content-container']}>
            <h1 className={styles['section-sub-title']}>
                {props.content.subTitleTwo}
            </h1>
            <div className={styles['reel-container']}>
                <div
                    className={
                        styles[
                            hasReelCrafterImage()
                                ? 'reel-image-container'
                                : 'invisible'
                        ]
                    }
                >
                    <img
                        className={styles['reel-image']}
                        src={props.content.reelCrafterImage.url}
                        alt="Profile Picture of Diane"
                    ></img>
                </div>
                <div
                    className={
                        styles[hasReelCrafterUrl() ? 'reel' : 'invisible']
                    }
                >
                    <iframe
                        width="100%"
                        height={props.content.reelCrafterHeight}
                        scrolling="no"
                        frameBorder="no"
                        src={props.content.reelCrafterUrl}
                    ></iframe>
                </div>
            </div>

            <h1 className={styles['section-sub-title']}>
                {props.content.subTitleOne}
            </h1>
            <div className={styles['video-container']}>{generateVideos()}</div>
        </div>
    );
}
