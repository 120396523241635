import { GRAPH_URL } from '../constants/graphql-constants';
import { request, gql } from 'graphql-request';
import { AboutMeModel } from '../models/aboutmemodel.model';

export namespace AboutMeService {
    export function GetAboutMeText(): Promise<AboutMeModel[]> {
        const query: string = gql`
            {
                abouts {
                    description {
                        html
                    }
                    profileImage {
                        url
                    }
                    picture1 {
                        url
                    }
                    picture2 {
                        url
                    }
                    picture3 {
                        url
                    }
                    header
                    backgroundImage {
                        url
                    }
                }
            }
        `;

        return request<AboutMeModel[]>(GRAPH_URL, query).then((result: any) => {
            return result.abouts;
        });
    }
}
