import { ReactElement, useState } from 'react';
import styles from './ScrollButton.module.scss';

export default function ScrollButton(): ReactElement {
    const [visible, setVisible] = useState(false);

    function toggleVisible(): void {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10) {
            setVisible(true);
        } else if (scrolled <= 10) {
            setVisible(false);
        }
    }

    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className={styles['button-constraints']}>
            <button
                className={styles['scroll-button']}
                style={{ display: visible ? 'block' : 'none' }}
                onClick={scrollToTop}
            >
                ↑
            </button>
        </div>
    );
}
