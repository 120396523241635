import { GRAPH_URL } from '../constants/graphql-constants';
import { request, gql } from 'graphql-request';
import { ContactModel } from '../models/contact.model';

export namespace ContactService {
    export function GetContactText(): Promise<ContactModel[]> {
        const query: string = gql`
            {
                contacts {
                    description {
                        html
                    }
                    title
                    socialsHeader
                    linkedInLink
                    instagramLink
                    facebookLink
                }
            }
        `;

        return request<ContactModel[]>(GRAPH_URL, query).then((result: any) => {
            return result.contacts;
        });
    }
}
