import { ReactElement } from 'react';
import { ContactModel } from '../../models/contact.model';
import styles from './Contact.module.scss';

interface ContactProps {
    content: ContactModel;
}

export default function Contact(props: ContactProps): ReactElement {
    return (
        <div>
            {/* <div className={styles['top-angle']}></div> */}
            <div className={styles['content-background']}>
                <div className={styles['content-container']}>
                    <div className={styles['text-container']}>
                        <h2 className={styles['header']}>
                            {props.content.title}
                        </h2>
                        <h1
                            className={styles['description']}
                            dangerouslySetInnerHTML={{
                                __html: props.content.description.html,
                            }}
                        ></h1>
                    </div>
                    <div className={styles['button-container']}>
                        <div>
                            <a
                                className={styles['transparent-button']}
                                href={props.content.linkedInLink}
                            >
                                <img
                                    className={styles['svg']}
                                    src="imdb.svg"
                                    alt="IMDb Link"
                                ></img>
                            </a>
                            <a
                                className={styles['transparent-button']}
                                href={props.content.instagramLink}
                            >
                                <img
                                    className={styles['svg']}
                                    src="insta.svg"
                                    alt="Instagram Link"
                                ></img>
                            </a>
                            <a
                                className={styles['transparent-button']}
                                href={props.content.facebookLink}
                            >
                                <img
                                    className={styles['svg']}
                                    src="fb.svg"
                                    alt="Facebook Link"
                                ></img>
                            </a>
                        </div>
                        <div className={styles['email-button-container']}>
                            <a
                                className={styles['button']}
                                href={'mailto:' + props.content.socialsHeader}
                            >
                                EMAIL
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
