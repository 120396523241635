import { ReactElement } from 'react';
import { ContactModel } from '../../models/contact.model';
import styles from './EmailButton.module.scss';

interface ContactProps {
    content: ContactModel;
}

export default function EmailButton(props: ContactProps): ReactElement {
    return (
        <div>
            <div className={styles['content-container']}></div>
        </div>
    );
}
